import React, { FC } from 'react'
import cx from 'classnames'
import css from './ErrorPage.module.scss'
import Link from 'next/link'

const ErrorPage: FC = () => {
  return (
    <section className={css.container}>
      <h1 className={cx(css.title, 'apoc', 'h1')}>404</h1>
      <p className={css.descr}>Страница не найдена</p>
      <p className={css.help}>
        Можете перейти на{' '}
        <Link href="/">
          <a className={css.link} title="Главная страница">
            главную
          </a>
        </Link>{' '}
        или посмотреть наш{' '}
        <Link href="/journal">
          <a className={css.link} title="Журнал">
            журнал
          </a>
        </Link>
      </p>
    </section>
  )
}

export default ErrorPage

import React from 'react'
import { GetStaticProps, NextPage } from 'next'
import MaxWidth from '../components/layout/MaxWidth/MaxWidth'
import { initializeApollo } from '../lib/apolloClient'
import globalCache from '../lib/globalCache'
import ErrorPageComponent from '../components/ErrorPage/ErrorPage'
import { HeaderColors } from '../components/types'
import { createPageSeo, getPageSeo } from '../lib/createPageSeo'

const ErrorPage: NextPage = () => {
  return (
    <MaxWidth>
      <ErrorPageComponent />
    </MaxWidth>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()
  await globalCache(apolloClient)

  let seo

  const seoData = await getPageSeo(apolloClient, 'not-found')

  if (seoData?.data?.element) {
    seo = createPageSeo(seoData.data)
  }

  return {
    props: {
      __APOLLO_STATE__: apolloClient.cache.extract(),
      headerInitColor: HeaderColors.white,
      errorPage: true,
      seo,
    },
    revalidate: 60,
  }
}

export default ErrorPage
